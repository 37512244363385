<template>
  <v-app>
    <Loading v-show="loader" />
    <v-main v-show="!loader">
      <div id="nav">
        <router-link to="/">Home</router-link> |
        <router-link to="/about">About</router-link> |
        <router-link to="/timeline">Timeline</router-link>
      </div>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import Loading from '@/components/Loading'

export default {
  name: 'App',
  data () {
    return {
      loader: true
    }
  },
  mounted () {
    setTimeout(() => {
      this.loader = false
    }, 1250)
  },
  components: {
    Loading
  }
}
</script>

<style lang="scss">
#nav {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
