<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
          :src="require('../assets/jiclogo.gif')"
          class="my-3"
          contain
          height="200"
        />
      </v-col>

      <v-col class="mb-4">
        <p class="subheading font-weight-regular">
          奉仕と国際理解、個人の成長を大きな目標とする<br>
          自修館の国際ボランティア組織のひとつとして活動しています。
        </p>
      </v-col>

      <v-col
        class="mb-5"
        cols="12"
      >
        <h2 class="headline font-weight-bold mb-3">
          活動歴
        </h2>

        <v-row justify="center">
          <a
            v-for="(his, i) in history"
            :key="i"
            :href="his.href"
            class="subheading mx-3"
            target="_blank"
          >
            {{ his.text }}
          </a>
        </v-row>
      </v-col>

      <v-col
        class="mb-5"
        cols="12"
      >
        <h2 class="headline font-weight-bold mb-3">
          各種リンク
        </h2>

        <v-row justify="center">
          <a
            v-for="(link, i) in importantLinks"
            :key="i"
            :href="link.href"
            class="subheading mx-3"
            target="_blank"
          >
            {{ link.text }}
          </a>
        </v-row>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'HelloWorld',

  data: () => ({
    importantLinks: [
      {
        text: '自修館中等教育学校',
        href: 'https://www.jishukan.ed.jp/'
      },
      {
        text: '自修館FaceBook',
        href: 'https://www.facebook.com/jishukan.isehara/'
      },
      {
        text: 'わかちあいプロジェクト',
        href: 'https://wakachiai.jp/'
      },
      {
        text: 'NPO法人 日韓アジア基金',
        href: 'http://www.iloveasiafund.com/'
      }
    ],
    history: [
      {
        text: 'フェアトレード商品販売',
        href: '#'
      },
      {
        text: 'グローバルフェスタ',
        href: '#'
      },
      {
        text: 'SOC授賞式',
        href: '#'
      }
    ]
  })
}
</script>
