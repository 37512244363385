<template>
  <div class="fullview">
    <div class="loading-spacer"></div>
    <vue-loading
      type="spiningDubbles"
      color="#4FC3F7"
      :size="{ width: '100px', height: '100px' }"
      >
    </vue-loading>
  </div>
</template>

<script>
import { VueLoading } from 'vue-loading-template'

export default {
  name: 'Loading',
  components: {
    VueLoading
  }
}
</script>

<style>
.fullview {
  width: 100%;
  height: 100%;
  background: #fefefe;
  position: fixed;
  top: 0;
  left: 0;
}

.loading-spacer {
  height: 30%;
}
</style>
