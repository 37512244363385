<template>
<v-container fluid>
  <v-timeline
    align-top
    :dense="$vuetify.breakpoint.smAndDown"
  >
    <v-timeline-item
      v-for="(item, i) in items"
      :key="i"
      :color="item.color"
      :icon="item.icon"
      fill-dot
    >
      <template v-slot:opposite>
        <span
          :class="`headline font-weight-bold ${item.color.split(' ')[0]}--text`"
          v-text="item.date"
        ></span>
      </template>
      <v-card
        :color="item.color"
        dark
      >
        <v-card-title class="text-h6">{{ item.title }}</v-card-title>
        <v-card-text class="white text--primary">
          <p v-text="item.text" class="pt-6" />
        </v-card-text>
      </v-card>
    </v-timeline-item>
  </v-timeline>
</v-container>
</template>

<script>
export default {
  data: () => ({
    items: [
      {
        color: 'red lighten-1',
        icon: 'mdi-star',
        date: '2018/11/00',
        title: 'JIC発足',
        text: '自修館インターアクトクラブ(JIC)が発足しました。'
      },
      {
        color: 'purple darken-1',
        icon: 'mdi-book-variant',
        date: '2019/01/27',
        title: 'NL66: 発送ボランティア',
        text: '日韓アジア基金ニュースレターの発送ボランティアを行いました。日韓アジア基金を支援している方々にニュースレターを発送するための封筒詰め、ラベルシール針、支援依頼のお手紙記入などを行いました。ボランティアを通して、カンボジアの学校の現状を学ぶことができました。'
      },
      {
        color: 'green darken-1',
        icon: 'mdi-shopping',
        date: '2019/03/26',
        title: '第一回フェアトレード in 保護者会',
        text: '自修館の保護者会にて、生徒の保護者向けに「わかちあいプロジェクト」の商品を使ったフェアトレード商品販売イベントを行いました。フェアトレード商品を販売する中で、フェアトレード商品の大切さを理解することができました。'
      },
      {
        color: 'orange darken-3',
        icon: 'mdi-forum',
        date: '2019/04/13',
        title: 'アジア文化交流～日中韓親睦会～',
        text: '土曜セミナーにおいて、中国や韓国の方々と文化や世界情勢、アジアにおける社会問題などに関して意見交換を行いました。中国や韓国の伝統衣装を着るという貴重な体験もできました。この交流会を通して、中国や韓国の新しい面が見え、アジアに対するイメージの変化、認識がありました。'
      },
      {
        color: 'purple darken-1',
        icon: 'mdi-book-variant',
        date: '2019/07/27',
        title: 'NL68: 発送ボランティア',
        text: '二度目の日韓アジア基金ニュースレターの発送ボランティアを行いました。前回同様、ニュースレターの発送の準備のお手伝いを行いました。'
      },
      {
        color: 'indigo',
        icon: 'mdi-google-earth',
        date: '2019/09/29',
        title: 'グローバルフェスタ',
        text: ''
      },
      {
        color: 'green darken-1',
        icon: 'mdi-shopping',
        date: '2019/10/17',
        title: '第二回フェアトレード in 文化祭',
        text: '自修館で行われた文化祭にて、第二回になるフェアトレード商品販売イベントを行いました。'
      }
    ]
  })
}
</script>
